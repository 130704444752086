.componentBase {
  display: grid;
  color: var(--primary-color);
  background-color: var(--primary-background-color);
  position: relative;
  overflow: hidden;
  z-index: 0;

  &.componentBaseShadowCasting {
    isolation: isolate;
    position: relative;
    z-index: 0;

    & > :not(.shadowCastingLayout) {
      z-index: 2;
    }

    & > .shadowCastingLayout {
      z-index: 1;
      position: absolute;
      width: min(1080px, 100vw);
      max-height: none;
      max-width: none;
      right: 0;
      top: 0;
    }

    /*
      Prevents hard edges of the 'shadow casting' on narrow hero's.
      The hero is the first-of-type section on every page.
    */
    &:first-of-type {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background: linear-gradient(
          to top,
          var(--primary-background-color),
          transparent
        );
        height: 200px;
        z-index: 1;
      }
    }
  }
}

.padding {
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  position: relative;
  z-index: 0;

  & > * {
    z-index: 1;
  }
}

.lg {
  --padding-top: var(--size-60);
  --padding-bottom: var(--size-60);

  @media (--viewport-sm) {
    --padding-top: var(--size-80);
    --padding-bottom: var(--size-80);
  }

  @media (--viewport-md) {
    --padding-top: var(--size-120);
    --padding-bottom: var(--size-120);
  }
}

.md {
  --padding-top: var(--size-30);
  --padding-bottom: var(--size-30);

  @media (--viewport-sm) {
    --padding-top: var(--size-40);
    --padding-bottom: var(--size-40);
  }

  @media (--viewport-md) {
    --padding-top: var(--size-60);
    --padding-bottom: var(--size-60);
  }
}

.sm {
  --padding-top: var(--size-16);
  --padding-bottom: var(--size-16);

  @media (--viewport-sm) {
    --padding-top: var(--size-20);
    --padding-bottom: var(--size-20);
  }

  @media (--viewport-md) {
    --padding-top: var(--size-30);
    --padding-bottom: var(--size-30);
  }
}

.componentHolidaySparkles {
  background-image: url(/images/holiday/bg-sparkles-section-480@2x.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--color-dark-800);

  @media (--viewport-sm) {
    background-image: url(/images/holiday/bg-sparkles-section-1024@2x.jpg);
  }

  @media (--viewport-lg) {
    background-image: url(/images/holiday/bg-sparkles-section-1440@2x.jpg);
  }
}
