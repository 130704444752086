:root {
  --motion-duration-150: 150ms;
  --motion-duration-200: 200ms;
  --motion-duration-350: 350ms;
  --motion-duration-400: 400ms;
  --motion-duration-500: 500ms;
  --motion-duration-800: 800ms;

  --motion-ease: ease;
  --motion-ease-in-out: ease-in-out;
  --motion-ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
}
