.componentXxxs {
  font-size: var(--font-size-16);
  line-height: var(--line-height-small-heading);
}

.componentXxs {
  font-size: var(--font-size-22);
  line-height: var(--line-height-small-heading);
}

.componentXs {
  font-size: var(--font-size-25);
  line-height: var(--line-height-small-heading);
}

.componentSm {
  font-size: var(--font-size-25);
  font-size: var(--font-size-25-30);
  line-height: var(--line-height-heading);
}

.componentMd {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-40);
  line-height: var(--line-height-heading);
}

.componentLg {
  font-size: var(--font-size-34);
  font-size: var(--font-size-34-48);
  line-height: var(--line-height-heading);
}

.componentXl {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-56);
  line-height: var(--line-height-heading);
}

.componentXxl {
  font-size: var(--font-size-56);
  font-size: var(--font-size-56-115);
  line-height: var(--line-height-heading);
}

.componentBase {
  color: var(--primary-color);
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing-heading);
  font-weight: var(--font-weight-400);
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  text-wrap: balance;
}
