:root {
  --shadow-color-rgb-dark: 47, 40, 33;
  --shadow-color-rgb-black: 0, 0, 0;

  --shadow-card: 0 20px 20px rgba(var(--shadow-color-rgb-dark), 0.02),
    0 20px 20px rgba(var(--shadow-color-rgb-dark), 0.03),
    0 7px 16px rgba(var(--shadow-color-rgb-dark), 0.03),
    0 0 0 rgba(var(--shadow-color-rgb-dark), 0.03);

  --shadow-card-black: 0 20px 20px rgba(var(--shadow-color-rgb-black), 0.2),
    0 20px 20px rgba(var(--shadow-color-rgb-black), 0.3),
    0 7px 16px rgba(var(--shadow-color-rgb-black), 0.3),
    0 0 0 rgba(var(--shadow-color-rgb-black), 0.3);
}
